import React from 'react';
import './About.css'; // Import the CSS file for styling

function About() {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>
        We are a team of dedicated professionals providing expert consulting services in various fields.
      </p>
      <p>
        Our mission is to help businesses thrive by offering tailored solutions and strategies.
      </p>
    </div>
  );
}

export default About;
